@import "../core";

.Slider {
  min-height: 500px;
  height: 100%;

  .title {
    position: absolute;

    font-size: 26px;
    line-height: 39px;
    width: 80vw;
    padding: 0 0 0 48px;
    @include respond-to(desktop) {
      font-size: 60px;
      line-height: 68px;
      width: 60vw;
      padding: 0 0 0 75px;
    }

    z-index: 3;
    bottom: 22%;
    color: white;
    text-shadow: 4px 5px 9px rgba(0, 0, 0, 0.68);
  }

  .carouselImage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: .3;
      z-index: 2;
    }
  }

  .scale {
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: 2;
  }

  h4.caption {
    position: absolute;
    z-index: 2;
    color: white;

    bottom: 6px;
    right: 24px;
    font-size: 18px;

    @include respond-to(desktop) {
      bottom: 40px;
      right: 50px;
      font-size: 20px;

    }

  }

  .images {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    height: 100%;
    width: 100%;

    ul {
      pointer-events: auto;
      margin: 10px;
      text-align: center;

      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
        z-index: 9;

        button {
          position: absolute;
          z-index: 9;
          right: 5px;
          top: 5px;
        }

        .EditableImage {
          border-radius: 10px;
          border: 2px solid black;
          min-height: auto;
          width: 200px;
          height: 150px;

          .FileDropZone p span {
            font-size: 10px;
            padding: 0;
          }

          .caption span[contenteditable="true"] {
            border-bottom: 1px dashed white;
          }
        }

        .addImage {
          padding: 10px;
          border-radius: 10px;
          width: 200px;
          height: 150px;
          background-color: rgba(255, 255, 255, .80);

          .plus {
            display: block;
            font-size: 60px;
          }
        }
      }
    }
  }

  .carousel-fade .carousel-item {
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity .75s ease-in-out;
  }

  .carousel-fade .carousel-item.active {
    opacity: 1;
    position: relative;
  }

  .carousel,
  .carousel-inner,
  .carousel-item {
    width: 100%;
    height: 100%;
  }

  .carousel {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    left: 0;

    .carousel-indicators {
      bottom: 40px;

      li {
        height: 10px;
        border-radius: 5px;
        width: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: none;
      }
    }
  }
}

