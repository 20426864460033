@import "../../core";
.Row {
  display: flex;
  align-items: center;

  width: 100%;
  height: 56px;
  padding: ($box_padding-small / 4) $box_padding-small / 2;

  font-weight: 400;

  background: $color_bg-light;
  border-radius: $border_radius;

  &.header-row {
    background-color: transparent;
    height: 32px;

    .active-sort-by {
      font-weight: bold;
      padding-left: 10px;
    }
    .sort-by {
      font-weight: normal;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  & + .Row {
    margin-top: $box_padding-small / 2;
  }

  & > div {
    flex: 1 0 auto;
    $tagW: 9%;
    $numW: 5.5%;
    $gapW: 2.5%;

    &:nth-of-type(1) {//expand button
      flex-basis: 3%;
    }

    &:nth-of-type(2) {
      flex-basis: 50%;
    }

    &:nth-of-type(3) {
      flex-basis: 1%;
    }

    &:nth-of-type(4) {
      flex-basis: 25%;
    }

    &:nth-of-type(5) {//checkbox
      flex-basis: 0;
    }

    @include respond-to(desktop) {
      &:nth-of-type(1) {
        flex-basis: 0;
      }

      &:nth-of-type(2) {
        flex-basis: 24%;
      }

      &:nth-of-type(3) {
        flex-basis: $tagW;
      }

      &:nth-of-type(4) {
        flex-basis: $tagW;
      }

      &:nth-of-type(5) {
        flex-basis: $tagW;
      }

      &:nth-of-type(6) {
        flex-basis: $gapW;
      }

      &:nth-of-type(7) {
        flex-basis: $numW;
      }

      &:nth-of-type(8) {
        flex-basis: $numW;
      }

      &:nth-of-type(9) {
        flex-basis: $numW;
      }

      &:nth-of-type(10) {
        flex-basis: $numW;
      }

      &:nth-of-type(11) {
        flex-basis: 0;
      }
    }
  }

  div.h3 {
    margin: 0;
    font-size: $font_size-xsmall;
    @include respond-to(desktop) {
      font-size: $font_size-small; // + 4px;
    }
  }

  .Row__metric {
    &.cvg {
      color: $color_brand-red;
    }

    &.pop {
      color: $color_brand-purple;
    }

    &.dud {
      color: $color_brand-green;
    }

    &.far {
      color: $color_brand-orange;
    }
  }

  .checkbox-element {
    margin-top: -6px;
    margin-right: -8px;
  }

}
