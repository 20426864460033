$font-family: 'IBM Plex Sans', sans-serif;

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 100px;
  padding: 36px 100px;
  background-color: white;
}
