.SideNav{
  @media (max-width: 1525px) {
    display: none;
  }
  @media (min-width: 1880px) {//where we have plenty of space, align the nav with the banner
    padding-left: 4em;
  }
  position: sticky;
  //padding-top: 60px;
  z-index: 2;
  top: 0;
  float: left;
  ul{
    margin: 40px 0 0 0;
    padding-left: 18px;
    list-style: none;
    li{
      margin: 30px 0 0 0;
      a{
        font-size: 18px;
        text-decoration: none;
        color: #000000;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
