@import "../../core";
.Card {
    width: 100vw;
    @include respond-to(desktop) {
        width: 400px;
    }
    min-height: 360px;

    background: white;
    border-radius: $border_radius;
    box-shadow: $box_shadow;
    overflow: hidden;

    .EditableImage {
        height: 225px;
        img {
            min-width: 100%;
            max-width: 125%;
            min-height: 100%;
            max-height: 125%;
        }
    }

    .expand-button {
        @include expand-button-card

    }

    h3.fixed-height {
        height: 2em;
    }
}
