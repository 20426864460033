/*
 * Variables
 */
@import "core";

/**
 * Extendables
 */

._vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}


/**
 * Globals
 */

* {
  position: relative;
  box-sizing: border-box;
}


body, html, #root {
  height: 100%;
  font-family: $font-family;
  background-color: #f6f6f6;
  overflow: hidden;
}

//.App {
//  margin-left: 40px;
//  padding: 2em;
//
//
//}
.floating {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.main-page-parent {
  width: 100%;
  height: calc(100% - #{$nav-bar-ht});
  overflow-x: hidden;
  overflow-y: visible;
  margin-top: $nav-bar-ht;
}

.main-page {
  //padding-top: 20vh;
  padding-bottom: 20vh;
  //overflow-y: auto;
}

.loading-notice {
  padding: 3em;
}

@mixin rounded-tag {
  font-size: 1rem;
  padding: $box_padding-small / 4 $box_padding-small;

  border: 1px solid $color_bg-dark;
  border-radius: $border_radius;
}

.compare-button {
  @include rounded-tag;
}

.tag-bubble {
  display: inline-block;

  @include rounded-tag;

  &--active {
    color: $color_font-light;
    background: $color_bg-dark;
  }

  & + & {
    margin-left: $box_padding-small;
  }


  &--edit {
    min-width: 150px;
  }
}

.ContentSection {
  span[contenteditable="true"] {
    border-bottom: 1px dashed black;
    min-height: 50px;
    min-width: 100px;
    display: block;
  }

  h1 {
    font-family: "IBM Plex Sans", sans-serif;
    color: #000000;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 1.2;
    font-weight: 400;
    font-style: normal;
  }

  .EditableImage {
    //min-height: auto;
    img {
      //min-width: 100%;
      //max-width: 100%;
      //min-height: 100%;
      //max-height: 100%;
      width: 100%;
      transform: none;
      position: relative;
    }
  }
}

@mixin content-blocks {
  h1 {
    font-weight: normal;
    font-size: 1.5em;
    @include respond-to(desktop) {
      font-size: 2.4em;
    }
  }

  p {
    font-size: 20px;
    font-weight: lighter;
  }

  &.container {
    padding-top: 25px;
    @include respond-to(desktop) {
      padding-top: 55px;
    }
    margin-top: 0.5em;

    @include respond-to(desktop) {
      padding-top: 70px;
      margin-top: 2em;
    }
  }
}

.ContentBlock {
  @include content-blocks;

  .col-sm-4 {
    h1 {
      @include respond-to(desktop) {
        padding-left: 0.8em;
      }
    }
  }
}

.DiagramBlock {
  @include content-blocks;

  .center {
    text-align: center;
  }

  .explore-content {
    .diagram-inline {
      display: inline-block;
      vertical-align: top;
    }

    .metrics-buttons {
      padding: 2em;

      button { //when not a nav link
        width: 5em;
        height: 5em;
        margin: 1em;
        border: none;
        display: inline-block;
        background: $color_bg-light;
        border-radius: $border_radius;

        &.selected {
          background: $color_bg-dark;
        }
      }
    }
  }

}

.ListContentBlock {
  @include content-blocks;

  .ListContentBlock_item {
    align-items: center;
    margin: 20px 0;

    h4, p {
      //margin: 0;
    }
  }
}

.ImageBlock {
  .imageList {
    display: flex;

    .EditableImage {
      min-width: 50px;

      img {
        width: 100%;
        position: relative;
      }
    }
  }
}

.offset-4 {//override for bootstrap columns
  @include respond-to(mobile) {
    margin-left: 0;
  }
}

@import "editables/toolbars/EditorToolbar";
@import "editables/Editables";
@import "components/index";


