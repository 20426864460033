@import "../../core";

.Explore {
  @include respond-to(desktop) {
    //padding: 120px 0 0 $box_padding-large;
    padding: 0 0 0 $box_padding-large;
  }
  position: absolute;
  top: 115px;
  left: 0;
  bottom: 0;
  right: 0;

  .case-studies {
    .case-studies--map {
      height: calc(100vh - 175px);

      .mapboxgl-map {
        height: calc(100vh - 175px);
      }
    }

    .case-studies--compare {
      overflow-x: auto;
      overflow-y: scroll;

      width: 100vw;
      @include respond-to(desktop) {
        width: calc(100vw - 385px);
      }

      height: calc(100vh - 175px);

      .compare-table {
        display: flex;
      }
    }

    .case-studies--card {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      height: 100%;
      width: 100%;

      .Card {
        display: inline-block;
        margin-right: $box_padding-large;
        margin-bottom: $box_padding-large;
      }
    }

    .scroll-list {
      width: 100%;
      //position: absolute;//TEMP for testing scroll - this should fill the 'flex' box on the right
      //height: 1000px;//TEMP
      //top:0;
      //left:0;
      //bottom:0;
      //right:0;
      //overflow: hidden;
      //width: 1500px;//TEMP
      //height: 900px;
    }

    .FileDropZone {
      width: 100%;
      height: 100%;
    }
  }

  .toggle {
    width: 35px;
    height: 35px;

    color: $color_font-light;
    font-weight: 600;
    text-align: center;

    background: $color_bg-dark;
    border: none;
    border-radius: 50%;
    box-shadow: $box_shadow;
    cursor: pointer;
  }

  @include respond-to(mobile) {
    .case-studies {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &__layout {
      width: 100%;
      height: 100%;
    }

    .FilterPanel {
      z-index: 2;
      background-color: white;
      padding: 1em;
    }

    //&__layout {
    //  .FilterPanel {
    //    display: none;
    //  }
    //  &--open {
    //    .FilterPanel {
    //      display: inherit;
    //    }
    //  }
    //}
  }

  @include respond-to(desktop) {

    display: grid;
    align-items: stretch;
    grid-template-rows: min-content auto;

    &__layout {
      display: flex;

      margin-top: $box_padding-small;

      & > div {
        &:first-of-type {
          //flex: 0 0 0;
          //padding-right: 0;
          //overflow: hidden;
          //z-index: 1;
          flex: 1;
          z-index: 2;
        }

        &:nth-of-type(2) {
          flex: 1;
          z-index: 2;
        }
      }

      &--open {
        & > div:first-of-type {
          flex: 0 0 350px;
          padding-right: $box_padding-large;
        }
      }
    }
  }

  .expand-button { //background for 'Cards' set in @see Card.scss
    @include corner-button;

    button { //when not a nav link
      outline: none;
      border: none;
      background-color: transparent;
    }
  }

  .checkbox-element {
    @include corner-button;

    &.selected {
      background: $color_bg-dark;
    }

    color: $color_font-light;
    font-size: $font_size-medium;
    font-weight: 100;

    background: rgba(#ffffff, 0.9);
    box-shadow: $box_shadow;


    span {
      @extend ._vertical-align;
    }
  }
}

.Modal {
  position: fixed;
  top: 115px; //TODO centralize top bar height
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  overflow-y: scroll;
  overflow-x: hidden;
}

.explore-content {
  .content {
    padding: $box_padding;

    .fg-metrics-container {
      @include respond-to(desktop) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
    }
  }

  h3 {
    margin: 0 0 $box_padding-small;
    //padding-left: $box_padding-small / 2;

    font-size: $font_size-bigger;
    font-weight: 500;

  }

  .anim-text {
    display: inline-block;
  }

  .checkbox {
    position: absolute;
    top: $box_padding-small;
    right: $box_padding-small;
  }

  .location {
    margin-bottom: $box_padding-small;
    padding-left: $box_padding-small / 4;

    font-size: $font_size-xsmall;
    //display: inline-block;
  }

  .tags {
    margin: $box_padding-small 0;
  }

  .metrics {
    display: flex;
    flex-wrap: wrap;
  }

  .metric {
    width: 50%;
    padding-right: $box_padding-small;
    margin: 0 0 $box_padding-small;

    &-value {
      font-size: $font_size-medium;
      font-weight: 200;
    }

    &-subtext {
      color: $color_font-lighter;
    }

    &-bar {
      $bar-height: 20px;

      height: $bar-height;
      margin: 2px 0;

      overflow: hidden;
      border-radius: 5px;

      &-value {
        height: $bar-height;
      }

      &-slider {
        @extend ._vertical-align;
        right: initial;

        width: $bar-height / 2;
        height: $bar-height / 2;

        background: $color_bg-light;
        border-radius: 50%;
        box-shadow: $box_shadow;
        cursor: pointer;
        transition: width .2s, height .2s;


        &:hover {
          transition: width .2s, height .2s;
          width: $bar-height / 1.5;
          height: $bar-height / 1.5;
        }
      }
    }

    &.cvg {
      .metric-bar {
        background: $color_brand-red--light;
      }

      .metric-bar-value {
        background: $color_brand-red;
      }
    }

    &.pop {
      .metric-bar {
        background: $color_brand-purple--light;
      }

      .metric-bar-value {
        background: $color_brand-purple;
      }
    }

    &.dud {
      .metric-bar {
        background: $color_brand-green--light;
      }

      .metric-bar-value {
        background: $color_brand-green;
      }
    }

    &.far {
      .metric-bar {
        background: $color_brand-orange--light;
      }

      .metric-bar-value {
        background: $color_brand-orange;
      }
    }
  }
}
