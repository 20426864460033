.RadioSlider {
    display: flex;

    padding: ($box_padding-small / 4) 0;

    background: $color_bg-light;
    border-radius: $border_radius;
    overflow: hidden;

    &__highlighter {
        z-index: 1;
        position: absolute;
        top: 0;

        height: 100%;

        background: $color_bg-dark;
        border-radius: $border_radius;
        box-shadow: $box_shadow;

        transition: left .25s;
    }

    &__option {
        z-index: 2;
        flex: 1;

        color: $color_font-medium;
        text-align: center;

        cursor: pointer;
        opacity: 1;
        transition: color .25s, opacity .25s;

        &:hover {
            opacity: .75;
            transition: color .25s, opacity .25s;
        }

        &--active {
            color: $color_font-light;

            transition: color .25s;

            &:hover { opacity: 1; }
        }
    }

}
