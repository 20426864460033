.FilterPanel {
  //position: sticky;
  //top: 75px;
  //align-self: flex-start;//this is needed to get 'sticky' elements to work in flex

  &__section {
    & + & {
      margin-top: $box_padding-small;
    }
  }

  h4 {
    margin: 0 0 $box_padding-small / 2;
    font-size: $font_size-xsmall;
  }

  height: calc(100vh - 175px);
  overflow: auto !important;
  overflow-y: scroll;

  .filter-panel-scroll {
height: auto;
  }

  a {
    opacity: 0.25;
    &.is-active {
      opacity: 0.75;
    }

    img.nav-image {
      display: inline-block;
      width: 25px;
      height: 25px;
      //opacity: .75;
      cursor: pointer;
      margin-right: 16px;

      & + img {
        margin-left: $box_padding-small;
      }
    }
  }


  .search-box {

    input {
      width: 100%;
      padding-left: ($box_padding-small / 2) + 20px;
    }

    &__icon {
      position: absolute;
      left: $box_padding-small / 2;
      top: 6px;

      width: 20px;
      height: 20px;
    }
  }

  .metric {
    & + .metric {
      margin-top: $box_padding-small / 4;
    }

    cursor: pointer;
    font-size: $font_size-small;

    .tag-bubble {
      margin-right: $box_padding-small;
    }
  }

  .compare-list {
    padding: $box_padding-small / 2;

    background: $color_bg-light;
    border-radius: $border_radius;

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        width: 100%;

        color: $color_font-medium;

        & + li {
          margin-top: $box_padding-small / 2;
        }

        div {
          flex: 1 1 auto;
        }

        button {
          flex: 0 1 20px;
          width: 20px;

          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }

    &__buttons {
      margin-top: 0.2em;
      margin-bottom: 1em;
      a:first-of-type {
        opacity: 0.75;
        &.is-active {
          opacity: 1;
        }
        @include rounded-tag;
        color: $color_font-light;
        background: $color_bg-dark;
      }

      a:last-of-type {
        //float: right;
      }
    }

    .compare-count {
      @extend ._vertical-align;
      right: -15px;
      left: initial;

      display: block;
      width: 20px;
      height: 20px;

      text-align: center;

      color: $color_font-light;
      font-size: 13px;
      font-weight: 600;

      background: $color_brand-orange;
      border-radius: 50%;
    }
  }
}
