.CompareView {
  display: table;
  border-spacing: 10px 0;


  .EditableImage {
    height: 225px;
    img {
      min-width: 100%;
      max-width: 125%;
      min-height: 100%;
      max-height: 125%;
    }
  }

  .row {
    &.isometric {
      .graphic {
        //transform: scale(0.6);
      }
    }

    &.plan {
      .EditableImage {
        height: 225px;
      }
    }

    &:first-child {
      & > * {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    &:last-child {
      & > * {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }

    display: table-row;

    & > * {
      max-width: 300px;
      min-width: 300px;
      display: table-cell;
      background-color: white;
      padding: 10px;
      word-break: break-word;
    }

    .Card__content {
      padding: 10px 10px 0;

      h3 {
        margin: 0;
      }
    }
  }

  .hero {
    .hero-image {
      .expand-button {
        @include expand-button-card;
        z-index: 2;
      }

      .EditableImage {
        height: 150px;
      }
    }
  }

  .location span {
    padding: 0;
  }

  .metric {
    width: 100%;

    &-text {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &-bar {
      margin: 0;
    }

    &-value {
      font-size: 28px;
    }

    &-subtext {
      font-size: 16px;
    }
  }
}
