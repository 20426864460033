$border_radius: 15px;

$box_padding-small: 18px;
$box_padding-large: 36px;
$box_padding: $box_padding-small $box_padding-large;
$box_shadow: 0 2px 5px rgba(black, .25);

$color_bg-light: white;
$color_bg-blue: #98CBE5;
$color_bg-medium: #D1D1D1;
$color_bg-dark: #333;

$color_font-light: white;
$color_font-medium: #5D5B5B;
$color_font-lighter: #999999;
$color_font-dark: black;
$color_font-blue: #16A0FB;

$color_brand-orange: #FE9219;
$color_brand-orange--light: #FEEDDA;
$color_brand-red: #F04A4A;
$color_brand-red--light: #FFD6D6;
$color_brand-purple: #461573;
$color_brand-purple--light: #CCBADB;
$color_brand-green: #67D9AE;
$color_brand-green--light: #BDE6E4;

$font_size-xsmall: 18px;
$font_size-small: 20px;
$font_size-medium: 1.5em;
$font_size-bigger: 1.65em;
$font_size-large: 48px;
$font_size-xlarge: 72px;

$font-family: 'IBM Plex Sans', sans-serif;

$nav-bar-ht: 82px;

$mobileBreak: 768px;
$largeBreak: 1024px;

$left-panel-width: 300px;

@mixin corner-button {
  $size: 32px;
  width: $size;
  height: $size;
  border-radius: $border-radius - 5px;
  cursor: pointer;
  text-align: center;
}

@mixin expand-button-card {
  position: absolute;
  cursor: pointer;
  top: $box_padding-small;
  left: $box_padding-small;
  background: rgba($color_bg-dark, 0.75);
}

@mixin respond-to($media) {
  @if $media == mobile {
    @media all and (max-width: $mobileBreak) { @content; }
  }
  @else if $media == desktop {
    @media all and (min-width: $mobileBreak+1px) { @content; }
  }
  @else if $media == large-desktop {
    @media all and (min-width: $largeBreak) { @content; }
  }
}
