.EditorToolbar {
  position: fixed; //we use getBoundingClientRect to position on screen at the correct position
  top: 300px;
  left: 300px;
  border: 1px solid #dddddd;
  background: #ffffff;

  .icon-button {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid gray;
    background: #eeeeee;
  }
  z-index: 2;

}

.EditorHighlightBox {
  position: fixed; //we use getBoundingClientRect to position on screen at the correct position
  top: 300px;
  left: 300px;
  width: 10px;
  height: 10px;
  border: 2px dotted red;
  z-index: 2;
  pointer-events: none;
}
