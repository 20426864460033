@import "../../core";
.FigureGround {
  display: inline-block;
  @include respond-to(mobile) {
    width: 100%;
  }
}

/*
.mapboxgl-canvas{
  width: 500px;
  height: 300px;
}*/
