@import "../../core";
.Details {
    background: white;
    border-radius: $border_radius;
    box-shadow: $box_shadow;
    overflow: hidden;

    @include respond-to(desktop) {
        margin: 0 1em;
    }

    &.explore-content {
        .content {
            @include respond-to(desktop) {
                padding: 18px 60px 12px 60px;
            }
        }
        .metric {
            @include respond-to(desktop) {
                width: 25%;
            }
        }

        h3 {
            font-size: 2em;
            font-weight: 500;
            margin-bottom: 0;

            @include respond-to(desktop) {
                font-size: 50px;
            }
        }
        .metric-value {
            font-size: 30px;
            @include respond-to(desktop) {
                font-size: 50px;
            }
        }
    }

    .EditableImage {
        &.banner {
            //height: 40vw;
        }
        img {
            width: 100%;
        }
    }

    .expand-button {
        @include expand-button-card;
        position: fixed;
        top: 130px;
    }

    .details-gmaps-link {
        text-align: right;
    }

    .EditableImage {
        margin-bottom: 1em;
    }
}
