.Login {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  div{
    align-self: center;
  }
  img{
    border-radius: 40px;
  }
  h1{
    /*font-family: $Korolev;*/
    font-weight: 700;
  }
  p{
    /*font-family: $Korolev;*/
    font-weight: 200;
  }

  button.login {
    padding: 10px 0;
    font-size: 16px;
    letter-spacing: 1px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-weight: bold;
    display: inline-block;
    background: #607CFF;
    color: rgb(255, 255, 255);
    width: 190px;
    cursor: pointer;
  }

}
