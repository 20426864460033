@import "../core";

$font-family: 'IBM Plex Sans', sans-serif;

.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #F4F7F6;

  padding: 0.5em 1em;

  @include respond-to(desktop) {
    padding: 2em 1em;
  }


  &.editable {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    z-index: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

  }

  li {
    float: right;

    &.logo-link {
      float: left;
      padding: 0 1em;
      @include respond-to(mobile) {
        width: 100%;
      }
      @include respond-to(desktop) {
        margin-left: 3em;
      }
    }

    a {
      display: block;
      color: black;
      text-align: center;
      padding: 14px 0 8px 0;
      margin-right: 24px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        border-bottom: 3px solid $color_font-lighter;
      }

      &.is-active {
        border-bottom: 3px solid black;
      }
    }
  }
}
